import React, { Component } from 'react';
// import Select from 'react-select';
// import { getCountrySelectOptions } from "./CountryCodes";
export default class RegistrationHtmlField extends Component {

  formatContent(question, formikProps) {
    const { replacableStrings, type } = this.props;
    let content = question.content;
    if(replacableStrings){
      replacableStrings.forEach(replacableString => {
        switch (replacableString.from) {
          case 'formik_values':
            content = content.replaceAll(replacableString.key, this.getMappedValue(getFormikValue(replacableString.value, formikProps, type), replacableString.mappings))
            break;
          default:
            content = content.replaceAll(replacableString.key, replacableString.value);
        }
      })
    }
    return content
  }

  render() {
    const { type, formikProps, question } = this.props;
    // if (question.custom_class === 'profile-image') {
    //   return (
    //     <div className={`col-xs-12 ${this.renderCustomSize(question, 1)}`} style={{paddingTop: "20px", paddingBottom: "20px"}}>
    //       {/* <ProfilePicture onDirty={this.setFiles} onDirtyPicture={this.onDirtyPicture} previewImage={this.props.profile_image} /> */}
    //       {/* <ProfilePicture onDirty={this.setFiles} previewImage={this.props.profile_image} /> */}
    //     </div>
    //   );
    // }
    return <div className="col-xs-12" dangerouslySetInnerHTML={{__html: this.formatContent(question, formikProps)}} />;
  }
}
